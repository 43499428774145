"use client";

import { queries } from "#/lib/atoms/queries";
import { cn } from "#/lib/utils";
import { Button } from "#/ui/button";
import StandardLoader from "#/ui/standard-loader";
import { ConstructOAuthLink } from "#/utils/OAuthUtils";
import clsx from "clsx";
import { useAtom } from "jotai";
import { useParams, useRouter } from "next/navigation";
import { Suspense, useCallback, useMemo, useState } from "react";

export const PosAuthView = (props) => {
  return (
    <Suspense>
      <PosAuthViewComponent {...props} />
    </Suspense>
  );
};

export const PosAuthViewComponent = ({ rootStyle }: { rootStyle?: any }) => {
  const router = useRouter();
  const [{ data, isLoading }] = useAtom(queries.posIntegrationsList);

  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded((prev) => !prev), []);
  const params = useParams();

  const onOauth = useCallback(
    ({
      _id,
      can_use_for_signup,
    }: {
      can_use_for_signup: boolean;
      _id: string;
    }) => {
      return () => {
        if (!can_use_for_signup) return router.push("/register");

        window.open(ConstructOAuthLink(_id, params!.site as string), "_self");
      };
    },
    [params],
  );

  const items = useMemo(() => {
    if (expanded) return data;
    return data?.slice(0, 4);
  }, [data, expanded]);

  if (isLoading) return <StandardLoader />;
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div
        className={cn("flex gap-4 justify-center flex-grow", {
          "grid grid-cols-1 md:grid-cols-2 justify-center items-center grow":
            items?.length > 1,
        })}
      >
        {items?.map((platform: any) => (
          <div
            key={platform.id}
            onClick={onOauth(platform)}
            className={clsx(
              "w-[13rem] h-[4rem] my-2 mx-2 cursor-pointer bg-background rounded-2xl items-center justify-center flex",
              rootStyle,
            )}
          >
            <img
              src={platform.image}
              alt={platform?.name}
              width={200}
              height={50}
              className="w-[200px] object-contain h-6"
            />
          </div>
        ))}
      </div>
      {data.length > items.length && (
        <Button onClick={toggle} type="button" variant="link">
          {!expanded ? "See More" : "See Less"}
        </Button>
      )}
    </div>
  );
};
