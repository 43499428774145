export const ConstructOAuthLink = (source: string, white_label: string) => {
  const redirect_back = window.location.protocol + "//" + window.location.host;
  let url =
    process.env.NEXT_PUBLIC_OAUTH_GATEWAY_URL +
    `?source=${source}&redirect_back=${redirect_back}`;
  if (white_label && white_label !== "stream") {
    url += `&white_label=${white_label}`;
  }
  return url;
};
