"use client";
import { cn } from "#/lib/utils";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { ChangeEventHandler } from "react";
import { twMerge } from "tailwind-merge";

export const Input = ({
  secure,
  placeholder,
  register,
  title,
  inputStyles,
  rootStyles,
  searchable,
  editable,
  disabled,
  titleStyles,
  onBlur,
  onChange,
}: {
  placeholder?: string;
  rootStyles?: any;
  register?: any;
  searchable?: boolean;
  secure?: boolean;
  title?: string;
  inputStyles?: any;
  titleStyles?: any;
  disabled?: boolean;
  editable?: boolean;
  onBlur?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <div
      className={clsx(cn(`relative`, rootStyles), {
        "cursor-not-allowed": disabled,
      })}
    >
      <label
        className={cn(
          "bg-card absolute left-[0.9rem] text-muted text-sm font-circular-std font-500 top-[-11px] z-[5]",
          titleStyles,
        )}
      >
        {title}
      </label>
      <div className="relative z-[1]">
        {searchable ? (
          <MagnifyingGlassIcon className="absolute left-3 top-4 w-5 text-muted" />
        ) : null}
        <input
          placeholder={placeholder}
          onBlur={onBlur}
          readOnly={editable === false}
          disabled={disabled ?? editable === false ?? false}
          spellCheck={false}
          className={clsx(
            twMerge(
              `text-circular-medium focus:ring-0 focus:border-primary text-foreground placeholder-muted outline-none h-[3.2rem] w-full focus:ring-0 pl-4 focus:border-[1px] bg-[transparent] text-sm rounded-2xl border-[1px] border-border`,
              inputStyles,
            ),
            {
              "cursor-not-allowed bg-background":
                disabled ?? editable === false ?? false,
              "bg-background pl-[2.5rem] border-none": searchable,
            },
          )}
          type={secure ? "password" : "none"}
          {...register}
          onChange={onChange || register.onChange}
        />
      </div>
    </div>
  );
};
