"use client";

import { SwitchRegionSelect } from "#/components/switch-region-select";
import { useCohere } from "#/hooks/use-cohere";
import { useLoggedIn } from "#/hooks/use-logged-in";
import { cn } from "#/lib/utils";
import { ThemeContext } from "#/providers/theme-provider";
import { StreamLogo } from "#/ui/logo";
import { Separator } from "#/ui/separator";
import { useRouter } from "next/navigation";
import { ReactNode, useContext, useEffect, useState } from "react";

export default function UnProtectedPage({
  children,
  skipRedirect,
  progress,
}: {
  children: ReactNode | ReactNode[];
  skipRedirect?: boolean;
  progress?: number;
}) {
  const isLoggedIn = useLoggedIn();
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const [isMounted, setIsMounted] = useState(false);
  useCohere();

  useEffect(() => {
    if (isLoggedIn && !skipRedirect) {
      router.replace("/menu");
    }
  }, [isLoggedIn, skipRedirect, router]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className="bg-card h-[100vh] w-full overflow-auto">
      <nav className="flex relative px-5 md:px-10 py-4 items-center bg-secondary justify-between">
        <StreamLogo />
        {isMounted && <SwitchRegionSelect />}
        <div className="absolute self-center items-center flex justify-center bottom-[-20px] left-0 h-6 z-10 w-full">
          <div className="h-14 w-14 md:h-20 md:w-20 rounded-full justify-center bottom-[-7px] md:bottom-[-20px] absolute items-center flex border-2 border-border bg-background">
            <img
              className="h-[2rem] w-[2rem] md:h-[3rem] md:w-[3rem] pb-1 object-contain"
              src={theme?.iconUrl}
            />
          </div>
        </div>
      </nav>
      <div className="relative w-full">
        <Separator className="h-[2.5px]" />
        <Separator
          className={cn(
            "h-[2.5px] absolute bg-primary top-0",
            `w-[${progress || "0"}%]`,
          )}
        />
      </div>

      {isMounted && children}
    </div>
  );
}
