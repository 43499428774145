"use client";
import { WelcomeHeader } from "#/app/[site]/site/[lang]/app/(onboarding)/onboarding-header";
import UnProtectedPage from "#/components/layout/unprotected-page";
import { PosAuthView } from "#/components/onboarding/pos-auth-view";

import { useTStream } from "#/hooks/use-t-stream";
import { mutations } from "#/lib/atoms/mutations";
import { Button } from "#/ui/button";
import { ScrollArea } from "#/ui/scroll-area";
import { Separator } from "#/ui/separator";
import { Input } from "#/ui/standard-input";
import { useAtom } from "jotai";
import { Link, useTransitionRouter } from "next-view-transitions";
import { Suspense, useCallback } from "react";
import { useForm } from "react-hook-form";

export default function WelcomePageClient() {
  return (
    <UnProtectedPage>
      <Suspense>
        <Component />
      </Suspense>
    </UnProtectedPage>
  );
}

function Component() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { t } = useTStream("common");

  const [{ mutateAsync: checkUser, isPending }] = useAtom(
    mutations.authExisting,
  );

  const router = useTransitionRouter();

  const onUpdate = useCallback(
    async (data: any) => {
      try {
        const exists = await checkUser(data);
        const route = exists?._id ? "login" : "register";

        router.push(`/${route}?email=${encodeURIComponent(data.email)}`);
      } catch (error) {
        console.error(error);
      }
    },
    [checkUser, router],
  );

  return (
    <ScrollArea className="pt-[5%]">
      <form
        onSubmit={handleSubmit(onUpdate)}
        className="w-full overflow-none space-y-8 items-center flex flex-col justify-center"
      >
        <div className="md:max-w-[470px] px-10 md:px-0 space-y-10 w-full items-center justify-center">
          <WelcomeHeader
            title={t("Welcome Back")}
            description={t("Enter email to continue")}
          />
          <Input
            title={t("Email")}
            placeholder={t("Email")}
            rootStyles="mt-10 mb-4 w-full"
            register={{ ...register("email") }}
          />
          <Button loading={isPending} className="w-full">
            {t("Continue")}
          </Button>
        </div>
        <Separator className="my-5" />
        <div className="flex flex-col items-center space-y-4">
          <div className="flex items-center space-x-2">
            <p className="text-muted font-400 text-sm">
              {t("Don't have an account yet?")}
            </p>
            <Link className="text-primary text-sm font-500" href="/register">
              {t("Sign up")}
            </Link>
          </div>
          <PosAuthView />
        </div>
      </form>
    </ScrollArea>
  );
}
