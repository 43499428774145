export const WelcomeHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div>
      <p className="text-center self-center text-3xl mt-5 md:mt-0 md:text-4xl text-foreground font-500">
        {title}
      </p>
      <p className="text-muted text-center self-center md:mt-5 text-base font-400">
        {description}
      </p>
    </div>
  );
};
